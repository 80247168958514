import React, { useEffect, useState } from "react"
import "../assets/fonts/Poppins/Poppins-Light.ttf"
import "../assets/fonts/Poppins/Poppins-Bold.ttf"
import "../assets/fonts/Poppins/Poppins-Regular.ttf"
import "../components/layout.css"
import styled from "styled-components"
import colors from "../colors"
import { Row } from "../components/atomics"
import { getQueryParam } from "../utils/query"
import { URL_EMAIL, URL_INSTA_FLYNOW, URL_WHATSAPP } from "../constants"
import { FaInstagram, FaWhatsapp } from "react-icons/fa"
import { MdMailOutline } from "react-icons/md"

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2% 8%;

  .icon {
    font-size: 2rem;
    color: ${colors.primary2};
    cursor: pointer;
    transition: all 0.4s linear;
  }
`

const Button = styled.a`
  text-align: center;
  padding: 15px 30px;
  color: white;
  background: ${colors.primary2};
  border-radius: 40px;
  font-family: 'Poppins-Bold';
  cursor: pointer;
  transition: all 0.4s linear;
  &:hover {
    opacity: 0.9;
  }
  text-decoration: none;
  width: 80%;
  align-self: center;
`

const GuiaDefinitivoParaProdutividade = () => {
  const [utm, setUtm] = useState("")
  const [email, setEmail] = useState("")

  const data = {
    id: "combo_apps_ebooks_curso",
    title: "COMPRA INDIVIDUAL",
    discontPercentage: "20",
    sections: [
      {
        section: "APLICATIVOS (PLANO PREMIUM VITALÍCIO)",
        data: [
          {
            title: "Flynow - Produtividade",
            text:
            "Gerencie suas tarefas, crie bons hábitos e conquiste suas metas. Tudo o que você precisa para ser mais produtivo em apenas 1 app!",
            img: require("../assets/images/logo.png"),
            price: "179,99",
            newPrice: "143,99",
            url: 'https://buy.stripe.com/14k8yjcOBdWt8Fi3cj?locale=pt-BR&prefilled_promo_code=BLACKFRIDAY20&prefilled_email='+email+'&utm_source='+utm,
          },
          {
            title: "Flynow - Finanças Pessoais",
            text:
              "Simplifique sua vida financeira! Tenha controle sobre seus gastos e orçamentos, estabeleça metas financeiras e obtenha estatísticas e gráficos sobre suas finanças, tudo em um lugar só!",
            img: require("../assets/images/app/finances/logo.png"),
            price: "149,99",
            newPrice: "119,99",
            url: 'https://buy.stripe.com/4gwaGrdSFcSpaNq5km?locale=pt-BR&prefilled_promo_code=BLACKFRIDAY20&prefilled_email='+email+'&utm_source='+utm,
          },
          {
            title: "Flynow - Frases de Motivação",
            text:
              "Este app é perfeito para te dar aquele empurrãozinho para começar o dia motivado, se inspirando com frases sobre empreendedorismo, disciplina, sabedoria e muito mais!",
            img: require("../assets/images/app/frases/logo.png"),
            price: "29,99",
            newPrice: "23,99",
            url: 'https://buy.stripe.com/dR615R9Cp6u1bRudQZ?locale=pt-BR&prefilled_promo_code=BLACKFRIDAY20&prefilled_email='+email+'&utm_source='+utm,
          },
        ],
      },
      {
        section: "EBOOKS",
        data: [
          {
            url: "/guia-definitivo-para-a-produtividade?cupom=BLACKFRIDAY20&utm_source="+utm,
            title: "Guia definitivo para a produtividade",
            text:
              "23 técnicas (+6 BÔNUS) para ser mais produtivo e alcançar seus objetivos!",
            img: require("../assets/images/ebookProdutividade.png"),
            price: "54,70",
            newPrice: "43,76",
          },
          {
            url: "/metas-e-planejamento?cupom=BLACKFRIDAY20&utm_source="+utm,
            title: "Metas e Planejamento",
            text:
              "6 passos essenciais para criar metas + 6 tipos de planejamentos + 3 bônus EXCLUSIVOS em PDF!",
            img: require("../assets/images/ebookMetas.png"),
            price: "34,70",
            newPrice: "27,76",
          },
          {
            url: "/guia-completo-habitos?cupom=BLACKFRIDAY20&utm_source="+utm,
            title: "Guia completo sobre hábitos",
            text:
              "10 táticas (+2 BÔNUS) incríveis para criar e manter hábitos no longo prazo!",
            img: require("../assets/images/ebookHabitos.png"),
            price: "34,70",
            newPrice: "27,76",
          },
        ],
      },
      {
        section: "CURSO",
        data: [
          {
            url: "/curso-domine-seus-habitos?cupom=BLACKFRIDAY20&utm_source="+utm,
            title: "Domine seus hábitos: A chave para uma vida extraordinária",
            text: `Desbloqueie seu verdadeiro potencial e construa uma vida extraordinária baseada em hábitos poderosos. Sua vida extraordinária espera por você!`,
            img: require("../assets/images/cursoHabitos.png"),
            price: "179,90",
            newPrice: "143,92",
          },
        ],
      },
    ],
  }

  const sendEvent = event => {
    if (window) {
      if (window.fbq) window.fbq("track", event)
      if (window.gtag) window.gtag("event", event)
    }
  }

  useEffect(() => {
    sendEvent("view_" + data.id)
    setUtm(getQueryParam("utm_source"))
    setEmail(getQueryParam("email"))
  }, [])

  const onCheckout = () => {
    sendEvent("click_checkout_" + data.id)
  }

  return (
    <Container>
      <h1
        style={{ textAlign: "center", fontWeight: "black", display: "inline" }}
      >
        <h1 style={{ color: colors.primary2, display: "inline" }}>
          BLACK FRIDAY:
        </h1>{" "}
        {data.title}
      </h1>
      <h4
            style={{
              background: colors.red,
              color: "#fff",
              textAlign: "center",
              margin: 5,
              padding: 5,
            }}
          >
              {data.discontPercentage}% OFF (SOMENTE ATÉ 30/11)
          </h4>
      {data.sections.map(item => (
        <>
          <br />
          <br />
          <h3 style={{ color: colors.primary2 }}>{item.section}</h3>
          {item.data.map(i => (
            <Row style={{ marginTop: 20 }}>
              <img src={i.img} style={{ height: 100, objectFit: "contain" }} />
              <div style={{ marginLeft: 10 }}>
                <h6 style={{ textTransform: "uppercase" }}>{i.title}</h6>
                <h5
                  style={{
                    fontWeight: "normal",
                    marginBottom: 4,
                    marginTop: 3,
                  }}
                >
                  {i.text}
                </h5>
                <h4 style={{ color: colors.primary2 }}>
                  <span style={{fontSize: '.8rem'}}>DE</span> <s>{i.price}</s> <span style={{fontSize: '.8rem'}}>POR</span> {i.newPrice}
                </h4>
                <a href={i.url}>
                  <button
                    style={{
                      cursor: "pointer",
                      color: colors.white,
                      backgroundColor: colors.primary2,
                      border: "none",
                      padding: "3px 15px",
                      borderRadius: 8,
                      fontSize: "0.8rem",
                    }}
                  >
                    Adquirir com 20%OFF
                  </button>
                </a>
              </div>
            </Row>
          ))}
        </>
      ))}

      <br />
      <br />
      <h3 style={{ textAlign: "center" }}>Alguma dúvida?</h3>
      <br />
      <span style={{ textAlign: "center" }}>
        Se tiver qualquer dúvida entre em contato conosco!
      </span>
      {[
        {
          link: URL_WHATSAPP,
          img: <FaWhatsapp className="icon" />,
          description: "31 999107753",
        },
        {
          link: URL_EMAIL,
          img: <MdMailOutline className="icon" />,
          description: "support@appflynow.com",
        },
        {
          link: URL_INSTA_FLYNOW,
          img: <FaInstagram className="icon" />,
          description: "@appflynow",
        },
      ].map(i => (
        <a
          href={i.link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ alignSelf: "center" }}
        >
          <Row style={{ marginTop: 10, alignItems: "center" }}>
            {i.img}
            <h5 style={{ color: colors.primary2 }}>{i.description}</h5>
          </Row>
        </a>
      ))}
    </Container>
  )
}
export default GuiaDefinitivoParaProdutividade
